<template>
  <!-- BEGIN: Content-->
  <div
    v-if="!loading"
    class="card"
  > <!-- estos paneles, de momento sólo los ve el admin -->
    <div
      v-b-toggle="`collapsePublicationStats`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Stats memoir publications</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsePublicationStats`"
    >
      <div class="card-content ">
        <div class="row grants__stats">
          <div class="col-md-3">
            <div class="card">
              <div class="card-header pb-0">
                <h5>Publications selected</h5>
              </div>
              <div class="card-body">
                <p class="display-5 mb-0">
                  {{ memoirStats ? memoirStats.length : 0 }}
                </p>
                <hr>
                <p class="mb-0">
                  <small><a
                    href="javascript:;"
                    @click="filterPubli"
                  >Show selected publications</a></small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header pb-0">
                <h5>Number of characters in total</h5>
              </div>
              <div class="card-body">
                <p class="display-5 mb-0">
                  {{ charNumber }}
                </p>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      publications: 'publications/items',
      memoirStats: 'publications/memoirStats',
      filtersTotal: 'filters/filters',
    }),
    charNumber() {
      let charNumber = 0

      if (this.memoirStats) {
        this.memoirStats.forEach(e => {
          charNumber += e.full_reference ? e.full_reference.length : 0
        })
      }

      return charNumber
    },
    filtersValues() {
      return Object.values(this.filtersTotal)
    },
  },
  async mounted() {
    setTimeout(async () => {
      await this.$store.dispatch('publications/getMemoirStats', {
        ...this.filtersTotal,
        show_memoir: 'true',
      })
    }, 100)
    this.loading = false
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async filterPubli() {
      this.$store.dispatch('filters/saveFilters', {
        value: 'true',
        field: 'show_memoir',
      })

      await this.$store.dispatch('publications/filter', this.filtersTotal)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
